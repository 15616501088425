@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color: rgb(3 7 18);
  height: 100vh;
  font-family: "Vazirmatn", sans-serif;
  color: #fff;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
